import React, { useEffect, useRef, useState } from "react";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import styles from "./index.module.scss";
import "./content.scss";
import classnames from "classnames";
import AudioControlsButton from "components/common/AudioControlsButton";
import Repository from "repositories/Repository";
import { parseChineseOnly } from "util/zhuyin";


const { ExtendedResourceRepository } = Repository;

const AboutTheAuthor = ({ data, style }) => {
  const { contents, defaultActive } = data;
  const wordRef = useRef();
  const [{ bookId,books }] = useStore(StoreTypes.books);
  const [{ fontSize, scrollPosition }] = useStore(StoreTypes.extendedContent);
  const [{ extendedContentTldrawEditor }] = useStore(StoreTypes.tldraw);
  const [{ tabIndex, html }, setState] = useSetState({
    tabIndex: defaultActive,
    html: "",
  });

  const book=books.find(info=>info.bookId===bookId)
  const elementaryHighGradeCheck =
    book.display_name.includes("國小國語5") ||
    book.display_name.includes("國小國語6");

  useEffect(() => {
    const getHtml = async () => {
      const htmlJson = await ExtendedResourceRepository.getJSONContent({
        bookId,
        pathName: contents[tabIndex].word
          ? contents[tabIndex].word.split(".json")[0]
          : "",
      });
      setState({ html: htmlJson.html });
    };
    
    getHtml();
  }, [bookId, contents, setState, tabIndex]);
  
  // 文字排版，如稿紙徹底對齊文字和標點
  useEffect(() => {
    const ruby = wordRef.current.querySelector('ruby');
    const rubyHeight = ruby ? getComputedStyle(ruby).fontSize : null;
    const punctuation = wordRef.current.querySelectorAll('sign');
    punctuation.forEach(element => {
      element.style.display = 'inline-block';
      element.style.height = rubyHeight;
    });
  })

  useEffect(() => {
    if (!extendedContentTldrawEditor || !html) return;

    // const contentWidth = extendedContentTldrawEditor.getCurrentPageShapes().find(shape => shape.type === "extendedContent")?.props.w;
    // if (contentWidth === wordRef.current.scrollWidth ) return; // 用寬度來避免重複執行
    const extendedContentShape = extendedContentTldrawEditor.getCurrentPageShapes().find(shape => shape.type === "extendedContent");
    if (!extendedContentShape) return;

    extendedContentTldrawEditor.run(() => {
      extendedContentTldrawEditor.updateShape({
        id: extendedContentShape.id,
        type: extendedContentShape.type,
        props: { w: wordRef.current.scrollWidth },
        x: 0,
        y: 0,
      });
      extendedContentTldrawEditor.setCameraOptions({
        constraints: {
          behavior: { x: "inside", y: "fixed" },
          bounds: {
            w: wordRef.current.scrollWidth,
            h: wordRef.current.scrollHeight,
            x: 0,
            y: 0,
          },
          padding: { x: 0, y: 0 },
          origin: { x: 1, y: 0 },
          initialZoom: "fit-y",
          baseZoom: "fit-y",
        },
      });
      extendedContentTldrawEditor.setCamera(extendedContentTldrawEditor.getCamera(), { reset: true });
    }, { ignoreShapeLock: true, history: "ignore" });
  }, [html, extendedContentTldrawEditor, fontSize]);

  return (
    <div className={classnames(styles.aboutTheAuthor, fontSize)}>
      {contents.length > 1 && (
        <div className={styles.tab}>
          {contents.map((list, index) => (
            <div
              className={classnames(styles.list, {
                [styles.active]: tabIndex === index,
              })}
              key={index}
              onClick={() => setState({ tabIndex: index })}
            >
              {list.tab}
            </div>
          ))}
        </div>
      )}
      <div className={styles.sound}>
        {contents[tabIndex].sound && (
          <div className={styles.radio}>
            <AudioControlsButton
              src={ExtendedResourceRepository.getDataSrc({
                bookId,
                pathName: contents[tabIndex].sound,
              })}
            />
          </div>
        )}
      </div>
      {contents[tabIndex].image && (
        <div className={styles.image}>
          <img
            src={`${ExtendedResourceRepository.getDataSrc({
              bookId,
              pathName: contents[tabIndex].image,
            })}`}
            alt=""
          />
        </div>
      )}
      <div className={styles.word} ref={wordRef}>
        {contents[tabIndex].word && (
          <div
            className={styles.word}
            dangerouslySetInnerHTML={{ __html: elementaryHighGradeCheck? parseChineseOnly(html, "aboutTheAuthor") : html }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default AboutTheAuthor;
