export const srgbToP3 = (srgbColor) => {
    // 將 sRGB 十六進制轉換為 0-1 範圍的值
    let r = parseInt(srgbColor.slice(1, 3), 16) / 255;
    let g = parseInt(srgbColor.slice(3, 5), 16) / 255;
    let b = parseInt(srgbColor.slice(5, 7), 16) / 255;
  
    // sRGB 到線性 RGB 的轉換
    r = r <= 0.04045 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.04045 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.04045 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  
    // 線性 RGB 到 XYZ 的轉換
    let x = r * 0.4124564 + g * 0.3575761 + b * 0.1804375;
    let y = r * 0.2126729 + g * 0.7151522 + b * 0.0721750;
    let z = r * 0.0193339 + g * 0.1191920 + b * 0.9503041;
  
    // XYZ 到 Display P3 的轉換
    let p3r = x *  2.493496911941425 + y * -0.9313836179191239 + z * -0.4027107844507168;
    let p3g = x * -0.8294889695615747 + y *  1.7626640603183463 + z *  0.0236246858419436;
    let p3b = x *  0.0358458302437845 + y * -0.0761723892680418 + z *  0.9568845240076872;
  
    // 線性 RGB 到 sRGB 的轉換（用於 Display P3）
    p3r = p3r <= 0.0031308 ? 12.92 * p3r : 1.055 * Math.pow(p3r, 1/2.4) - 0.055;
    p3g = p3g <= 0.0031308 ? 12.92 * p3g : 1.055 * Math.pow(p3g, 1/2.4) - 0.055;
    p3b = p3b <= 0.0031308 ? 12.92 * p3b : 1.055 * Math.pow(p3b, 1/2.4) - 0.055;
  
    // 確保值在 0-1 範圍內
    p3r = Math.max(0, Math.min(1, p3r));
    p3g = Math.max(0, Math.min(1, p3g));
    p3b = Math.max(0, Math.min(1, p3b));
  
    // 返回 Display P3 顏色字符串
    return `color(display-p3 ${p3r.toFixed(4)} ${p3g.toFixed(4)} ${p3b.toFixed(4)})`;
  }