import React, { useState, useEffect, useRef, useCallback } from "react";
// import ReactDOM from "react-dom";
import { Tldraw, createShapeId, DefaultColorThemePalette } from "tldraw";
import { useStore, StoreTypes } from "context";
import { groupPages, preserveSVGAspectRatio } from "util/book";
import { useFetchBooks } from "customHooks/book";
import { BookFlipType } from "constants/flipTypes";
import { TLDrawPainterEventType } from "constants/painterTypes";
import { InteractiveObject } from "./CustomShapes/InteractiveObject";
import { BookPage } from "./CustomShapes/BookPage";
import {
  ReaderToolType,
  ReaderZoomType
} from 'constants/ReaderTools';
import { TLDrawEvent } from 'events/EventTypes';
import { useEvent, EventBus } from 'events/EventBus';
import { DefaultPanelColors } from 'constants/colors';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { srgbToP3 } from "util/rgbToP3";

// function useTick(isEnabled = true) {
// 	const [_, setTick] = useState(0)
// 	const editor = useEditor()
// 	useEffect(() => {
// 		if (!isEnabled) return
// 		const update = () => setTick((tick) => tick + 1)
// 		editor.on('tick', update)
// 		return () => {
// 			editor.off('tick', update)
// 		}
// 	}, [editor, isEnabled])
// }

let pageData = []
let isPreparedPage = false;
let isPreparedDrawShapes = false;
let color = DefaultPanelColors[1]
const customShape = [InteractiveObject, BookPage];

const TldrawContainer = ({ book }) => {
  const [{ bookContent, interactiveObjectSVG, interactiveObjectJSON }] =
    useStore(StoreTypes.books);
  const [{ scale, isDoublePageMode, isMarkModeShow, pageIndex, fullWidthInfo }] = useStore(
    StoreTypes.reader
  );
  const [{ tldrawEditor }, tldrawDispatch] = useStore(StoreTypes.tldraw);
  const { fetchBook } = useFetchBooks();
  const [
    {
      fullWidthInfo: { mode: fullWidthMode },
    },
  ] = useStore(StoreTypes.reader);
  const { bookId, pageInfos, LRFlip } = book;

  const [bookPages, setBookPages] = useState([]);
  // const [editor, setEditor] = useState();
  const readerView = document.querySelector("#readerView");

  let width = 500;
  let height = 500;

  if (fullWidthMode) {
    width = readerView?.clientWidth;
    height = readerView?.clientHeight;
  } else {
    // TODO: 符合書本比例時，該以長為基準，還是寬為基準，以及對應長寬的計算，需要重新思考。尤其是螢幕超大，高 3000px ，而書高（book.height）是 1000px 的情況。
    if (
      book.height > readerView?.clientHeight &&
      readerView?.clientHeight * ((book.width * 2) / book.height) <
      readerView?.clientWidth
    ) {
      width = readerView?.clientHeight * ((book.width * 2) / book.height);
      height = readerView?.clientHeight;
    } else {
      width = readerView?.clientWidth;
      height = readerView?.clientWidth * (book.height / (book.width * 2));
    }
  }

  useEffect(() => {
    let pages = [];
    const threshold = 3;
    if (isDoublePageMode) {
      let startPage = Math.max(0, pageIndex * 2 - threshold * 2);
      let endPage = Math.min(
        pageIndex * 2 + 1 + threshold * 2,
        pageInfos.length - 1
      );
      for (let i = startPage; i <= endPage; ++i) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(0, pageIndex - threshold);
      let endPage = Math.min(pageIndex + threshold, pageInfos.length - 1);
      for (let i = startPage; i <= endPage; ++i) {
        pages.push(i);
      }
    }
    pages = pages.filter((page) => !bookContent[page]);
    if (pages.length > 0) {
      fetchBook({ bookId, pages });
    }
  }, [pageIndex, fetchBook, bookId, pageInfos, bookContent, isDoublePageMode]);

  useEffect(() => {
    const groupBookPages = groupPages(bookContent, isDoublePageMode);
    if (!groupBookPages[pageIndex]) return;

    setBookPages(groupBookPages[pageIndex]);

  }, [bookContent, isDoublePageMode, pageIndex, tldrawEditor]);

  useEffect(() => {
    if (!tldrawEditor || bookPages.length === 0 ) return;
    if (LRFlip === BookFlipType.RIGHT_TO_LEFT) {
      bookPages.reverse();
    }
    isPreparedPage = false;
    isPreparedDrawShapes = false;
    tldrawEditor.run(
      () => {
        if (!isPreparedPage) {
          tldrawEditor.deleteShapes(tldrawEditor.getCurrentPageShapes());

          bookPages.forEach((page, index) => {
            const shapeId = createShapeId(`bookPage-${page.html}`);
            tldrawEditor.createShape({
              id: shapeId,
              type: "bookPage",
              x: index ? width / 2 : 0,
              y: 0,
              isLocked: true,
              props: {
                w: width / 2,
                h: height,
                svg: page.svg,
                bookInfo: book,
              },
              meta: {
                pageIndex,
                isDoublePageMode,
                bookFlipType: LRFlip,
              },
            });
            tldrawEditor.sendToBack([shapeId]);

            const interactiveObjectId = createShapeId(`interactiveObject-${page.pageIndex}`);
            tldrawEditor.createShape({
              id: interactiveObjectId,
              type: "interactiveObject",
              x: index ? width / 2 : 0,
              y: 0,
              isLocked: true,
              props: {
                svg: interactiveObjectSVG[page.pageIndex] || "",
                json: interactiveObjectJSON[page.pageIndex] || {},
                bookInfo: book,
                w: width / 2,
                h: height,
              },
              meta: {
                bookFlipType: LRFlip,
                pageIndex: page.pageIndex,
                isDoublePageMode,
              },
            });
          });

          const data = pageData[pageIndex] || []
          tldrawEditor.loadSnapshot(data);
        }
      },
      { history: "ignore", ignoreShapeLock: true }
    );

    tldrawEditor.run(() => {
      const currentPageShapes = tldrawEditor.getCurrentPageShapes();
      if (currentPageShapes.length === 0) return;
      currentPageShapes.forEach((shape) => {
        if (shape.type === 'draw') {
          if (shape.meta.color) {
            const specificShape = document.querySelector(`[data-shape-id="${shape.id}"]`) || document.querySelector(`[id="${shape.id}"]`)
            if (!specificShape) return;
            const pathElement = specificShape.querySelector("path"); // 找到 <path> 元素
            pathElement.setAttribute("fill", shape.meta.color);
          }
        }
        if (shape.type === 'highlight') {
          if (shape.meta.color) {
            const specificShapes = document.querySelectorAll(`[data-shape-id="${shape.id}"]`).length ? document.querySelectorAll(`[data-shape-id="${shape.id}"]`) : document.querySelectorAll(`[id="${shape.id}"]`);
            if (!specificShapes.length) return;
            specificShapes.forEach((specificShape) => {
              const pathElement = specificShape.querySelector("path");
              pathElement.dataset.color = shape.meta.color;
              pathElement.style.setProperty('--color', shape.meta.color);
            });
          }
        }
      });
    }, { history: "ignore", ignoreShapeLock: true });

      isPreparedPage = true;
      isPreparedDrawShapes = true;
  }, [
    pageIndex,
    width,
    height,
    book,
    bookPages,
    tldrawEditor,
    isDoublePageMode,
    LRFlip,
    interactiveObjectSVG,
    interactiveObjectJSON,
  ]);

  // useEffect(() => {
  //   isPreparedPage = false;
  // }, [pageIndex])


  useEffect(() => {
    if (!tldrawEditor || !width || !height) return;
        tldrawEditor.setCameraOptions({
          constraints: {
            initialZoom: "fit-max",
            baseZoom: "fit-max",
            bounds: { w: width, h: height, x: 0, y: 0 },
            padding: { x: 0, y: 0 },
            origin: { x: 0, y: 0 },
            behavior: "contain",
          },
          zoomSteps: [1, 2, 4, 8],
          zoomSpeed: 1,
          panSpeed: 1,
          isLocked: false,
        });
        tldrawEditor.setCamera(tldrawEditor.getCamera(), { reset: true });
        const shapes = tldrawEditor.getCurrentPageShapes();
        shapes.forEach((shape) => {
          if (shape.type === "bookPage" || shape.type === "interactiveObject") {
            tldrawEditor.updateShape({
              id: shape.id,
              type: shape.type,
              props: { w: width / 2, h: height },
              x: shape.x ? width / 2 : 0,
              y: 0,
            });
          }
        });
  }, [tldrawEditor, width, height]);


  const tldrawEventHandler = useCallback((payload) => {
    if (!tldrawEditor) return;
    switch (payload.type) {
      case ReaderToolType.Painting:
        tldrawEditor.setCurrentTool('draw');
        break;
      case ReaderToolType.Highlighter:
        tldrawEditor.setCurrentTool('highlight');
        break;
      case ReaderToolType.LaserPen:
        tldrawEditor.setCurrentTool('laser');
        break;
      case ReaderToolType.Select:
        tldrawEditor.setCurrentTool('select');
        break;
      case ReaderToolType.Drag:
        tldrawEditor.setCurrentTool('hand');
        break;
      case ReaderToolType.Eraser:
        tldrawEditor.setCurrentTool('eraser');
        break;
      case ReaderToolType.DeleteAll:
        confirmAlert({
          title: '全部刪除',
          message: '是否刪除當前物件',
          buttons: [
            {
              label: '確定',
              onClick: () => {
                const allShapes = tldrawEditor.getCurrentPageShapes();
                const annotationShapesId = allShapes.filter(shape => shape.type !== 'bookPage' && shape.type !== 'interactiveObject').map(shape => shape.id);
                tldrawEditor.deleteShapes(annotationShapesId);

                pageData[pageIndex] = null;
              }
            },
            {
              label: '取消'
            }
          ],
          closeOnEscape: true,
          closeOnClickOutside: true
        });
        break;
      case ReaderZoomType.OriginZoom:
        tldrawEditor.zoomToFit();
        break;
      case TLDrawPainterEventType.SetPenColor:
        color = payload.color;
        DefaultColorThemePalette.lightMode.black.solid = payload.color;
        DefaultColorThemePalette.lightMode.black.highlight.srgb = payload.color;
        DefaultColorThemePalette.lightMode.black.highlight.p3 = srgbToP3(payload.color);
        break;
      default:
        break;
    }
  }, [tldrawEditor, pageIndex])

  useEvent({ event: TLDrawEvent.TLDrawEvent }, tldrawEventHandler);



  return (
    <div
      style={{
        position: "fixed",
        width: width,
        height: height,
        inset: 0,
        margin: "0 auto",
      }}
      id="tlContainer"
    >
      <Tldraw
        hideUi
        shapeUtils={customShape}
        components={{
          DebugPanel: false,
          ZoomMenu: false,
          NavigationPanel: false,
          PageMenu: false,
        }}
        onMount={(editor) => {
          editor.setCurrentTool("draw");

          tldrawDispatch({ type: "SET_TLDRAW_EDITOR", payload: editor });
          editor.sideEffects.registerAfterChangeHandler('shape', (newShape) => {
            if (!isPreparedPage || !isPreparedDrawShapes) return;

            const index = editor.getCurrentPageShapes().find(shape => shape.type === 'bookPage').meta.pageIndex;
            if (!pageData[index]) {
              pageData[index] = [];
            }
            pageData[index] = editor.getSnapshot();

          });

          editor.sideEffects.registerAfterDeleteHandler('shape', (deletedShape, source, sth) => {
            if (!isPreparedPage || !isPreparedDrawShapes) return;

            const { pageIndex } = deletedShape.meta; 
            pageData[pageIndex] = editor.getSnapshot();
          });

          editor.sideEffects.registerAfterCreateHandler('shape', (newShape) => {
            if (newShape.type === 'bookPage' || newShape.type === 'interactiveObject') return;
            const pageIndex = editor.getCurrentPageShapes().find(shape => shape.type === 'bookPage').meta.pageIndex;
            editor.updateShape({ ...newShape, meta: { ...newShape.meta, pageIndex, ...(!newShape.meta.color && { color: newShape.type === 'highlight'?srgbToP3(color): color }) } });

            // if (newShape.type === 'draw') {
            //   if (newShape.meta.color) {
            //     const specificShape = document.querySelector(`[data-shape-id="${newShape.id}"]`);
            //     if (!specificShape) return;
            //     const pathElement = specificShape.querySelector("path"); // 找到 <path> 元素
            //     pathElement.setAttribute("fill", newShape.meta.color);
            //   }
            // }
            // if (newShape.type === 'highlight') {
            //   if (newShape.meta.color) {
            //     const specificShapes = document.querySelectorAll(`[data-shape-id="${newShape.id}"]`);
            //     if (!specificShapes.length) return;
            //     specificShapes.forEach((specificShape) => {
            //       const pathElement = specificShape.querySelector("path");
            //       pathElement.dataset.color = newShape.meta.color;
            //       pathElement.style.setProperty('--color', newShape.meta.color);
            //     });
            //   }
            // }
          });

          DefaultColorThemePalette.lightMode.black.solid = color;
          DefaultColorThemePalette.lightMode.black.highlight.srgb = color;
          DefaultColorThemePalette.lightMode.black.highlight.p3 = srgbToP3(color);
        }}
      ></Tldraw>
    </div>
  );
};

export default TldrawContainer;
