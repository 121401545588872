export const SideBarType = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  PAGE_SEARCHER: "PAGE_SEARCHER",
};

export const ToolBarDirection = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
};

export const ReaderToolType = {
  NextPage:"NextPage",
  PrevPage:"PrevPage",
  SynchronousCamera: "SynchronousCamera",
  BackToCatalog: "BackToCatalog",
  Menu: "Menu",
  Bookshelf: "Bookshelf",
  PageMode: "PageMode",
  Drag: "Drag",
  Painter: "Painter",
  Select: "Select",
  Painting: "Painting",
  Highlighter: "Highlighter",
  Shape: "Shape",
  Line: "Line",
  DeleteAll: "DeleteAll",
  Delete: "Delete",
  // ArrowLeft: 'ArrowLeft',
  // ArrowRight: 'ArrowRight',
  ViewPrevPage: 'ViewPrevPage',
  ViewNextPage: 'ViewNextPage',
  PageSearcherPrevPage: 'PageSearcherPrevPage',
  PageSearcherNextPage: 'PageSearcherNextPage',
  PageSearcher: "PageSearcher",
  FourZoomIn: "FourZoomIn",
  AllScreen: "AllScreen",
  IntervalZoomIn: "IntervalZoomIn",
  Whiteboard: "Whiteboard",
  NumberPicker: "NumberPicker",
  Img: "Img",
  Text: "Text",
  SVG: "SVG",
  AttendeeNumber: "AttendeeNumber",
  Bookmark: "Bookmark",
  ReaderOption: "ReaderOption",
  Activity: "Activity",
  OpenActivity: "OpenActivity",
  ExportInteractiveObject: "ExportInteractiveObject",
  RightSideBar: "RightSideBar",
  Mark: "Mark",
  FullWidth: "FullWidth",
  OfflineReaderToolBox: "OfflineReaderToolBox",
  StickyNote: "StickyNote",
  Expression: "Expression",
  MathTools: "MathTools",
  Toolbox: "Toolbox",
  Link: "Link",
  FullScreen: "FullScreen",
  Timer: "Timer",
  Stopwatch: "Stopwatch",
  MeasureTools: "MeasureTools",
  Protractor: "Protractor",
  ShortRuler: "ShortRuler",
  LongRuler: "LongRuler",
  IsoscelesTriangle: "IsoscelesTriangle",
  RightTriangle: "RightTriangle",
  StampCollection: "StampCollection",
  AnswerInteractiveTool: "AnswerInteractiveTool",
  PaiNote: "PaiNote",
  SubmitNote: "SubmitNote",
  PaiNoteRecord: "PaiNoteRecord",
  // SyncAnnotation: "SyncAnnotation"
  Redo: "Redo",
  Undo: "Undo",
  LaserPen: "LaserPen",
};

export const ReaderZoomType = {
  LeftTop: "LeftTop",
  RightTop: "RightTop",
  LeftBottom: "LeftBottom",
  RightBottom: "RightBottom",
  OriginZoom: "OriginZoom",
  AreaZoom: "AreaZoom",
  WheelZoom: "WheelZoom",
  PanZoom: "PanZoom",
  RangeZoom: "RangeZoom",
};

export const DefaultLeftReaderTools = [
  ReaderToolType.BackToCatalog,
  ReaderToolType.Toolbox,
];

export const ElectronDefaultReaderTools = [
  ReaderToolType.PaiNote,
  ReaderToolType.AnswerInteractiveTool,
  // ReaderToolType.Bookshelf,
  // ReaderToolType.Undo,
  // ReaderToolType.Redo,
  // ReaderToolType.SynchronousCamera,
  ReaderToolType.Drag,
  ReaderToolType.Painting,
  ReaderToolType.Highlighter,
  ReaderToolType.LaserPen,
  ReaderToolType.DeleteAll,
  ReaderToolType.Delete,
  ReaderToolType.ArrowLeft,
  ReaderToolType.PageSearcher,
  ReaderToolType.ArrowRight,
  ReaderToolType.FourZoomIn,
  ReaderToolType.AllScreen,
  ReaderToolType.IntervalZoomIn,
  ReaderToolType.FullScreen,
  ReaderToolType.SVG,
  ReaderToolType.NumberPicker,
  ReaderToolType.Select,
  // ReaderToolType.StickyNote,
  ReaderToolType.Whiteboard,
];

export const DefaultReaderTools = [
  ReaderToolType.PaiNote,
  ReaderToolType.AnswerInteractiveTool,
  // ReaderToolType.Bookshelf,
  // ReaderToolType.Redo,
  // ReaderToolType.SynchronousCamera,
  ReaderToolType.Drag,
  ReaderToolType.Painting,
  ReaderToolType.Highlighter,
  ReaderToolType.LaserPen,
  // ReaderToolType.Undo,
  ReaderToolType.DeleteAll,
  ReaderToolType.Delete,
  ReaderToolType.ArrowLeft,
  ReaderToolType.PageSearcher,
  ReaderToolType.ArrowRight,
  ReaderToolType.FourZoomIn,
  ReaderToolType.AllScreen,
  ReaderToolType.IntervalZoomIn,
  ReaderToolType.FullScreen,
  ReaderToolType.SVG,
  ReaderToolType.NumberPicker,
  ReaderToolType.Select,
  //ReaderToolType.StickyNote,
  //ReaderToolType.Link,
  ReaderToolType.Whiteboard,
  //ReaderToolType.StampCollection,
  // ReaderToolType.Protractor,
  // ReaderToolType.ShortRuler,
  // ReaderToolType.LongRuler,
  // ReaderToolType.IsoscelesTriangle,
  // ReaderToolType.RightTriangle,
  // ReaderToolType.SyncAnnotation,
  ReaderToolType.PaiNoteRecord
];

export const DefaultMiddleReaderTools = [
  ReaderToolType.PaiNote,
  ReaderToolType.AnswerInteractiveTool,
  ReaderToolType.Bookshelf,
  ReaderToolType.SynchronousCamera,
  ReaderToolType.Expression,
  ReaderToolType.Drag,
  ReaderToolType.Painting,
  ReaderToolType.Highlighter,
  ReaderToolType.LaserPen,
  ReaderToolType.Shape,
  ReaderToolType.DeleteAll,
  ReaderToolType.Delete,
  ReaderToolType.ArrowLeft,
  ReaderToolType.PageSearcher,
  ReaderToolType.ArrowRight,
  ReaderToolType.FourZoomIn,
  ReaderToolType.AllScreen,
  ReaderToolType.IntervalZoomIn,
  ReaderToolType.FullScreen,
  ReaderToolType.NumberPicker,
  ReaderToolType.Select,
  // ReaderToolType.StickyNote,
  ReaderToolType.Text,
  ReaderToolType.Whiteboard,
  ReaderToolType.Img,
  ReaderToolType.Mark,
  ReaderToolType.Line,
  ReaderToolType.SVG,
  ReaderToolType.PageMode,
  ReaderToolType.StickyNote,
  ReaderToolType.Link,
  ReaderToolType.MathTools,
  ReaderToolType.Menu,
  ReaderToolType.Bookshelf,
  ReaderToolType.ReaderOption,
  ReaderToolType.Timer,
  ReaderToolType.Stopwatch,
  ReaderToolType.MeasureTools,
  // ReaderToolType.Protractor,
  // ReaderToolType.ShortRuler,
  // ReaderToolType.LongRuler,
  // ReaderToolType.IsoscelesTriangle,
  // ReaderToolType.RightTriangle
  ReaderToolType.StampCollection,
  ReaderToolType.Undo,
  ReaderToolType.Redo,
  // ReaderToolType.SyncAnnotation
  ReaderToolType.PaiNoteRecord
];

export const DefaultRightReaderTools = [
  ReaderToolType.OfflineReaderToolBox,
  ReaderToolType.AttendeeNumber,
  ReaderToolType.Bookmark,
  ReaderToolType.Activity,
  ReaderToolType.SubmitNote,
  // OpenActivity,
  ReaderToolType.ExportInteractiveObject,
  // RightSideBar,
];

export const ToolboxCategory = {
  Settings: "Settings",
  Controls: "Controls",
  EditTools: "EditTools",
  TeachingTools: "TeachingTools",
  MathTools: "MathTools",
  OfflineTools: "OfflineTools",
};

export const DefaultToolboxCategory = [
  ToolboxCategory.Settings,
  ToolboxCategory.Controls,
  ToolboxCategory.EditTools,
  ToolboxCategory.TeachingTools,
  ToolboxCategory.MathTools,
];

export const ReaderToolbox = {
  Settings: [
    ReaderToolType.Bookshelf, 
    ReaderToolType.SVG,
    ReaderToolType.ReaderOption,
  ],
  Controls: [
    ReaderToolType.SynchronousCamera,
    ReaderToolType.BackToCatalog,
    ReaderToolType.Drag,
    ReaderToolType.Select,
    ReaderToolType.PageMode,
    ReaderToolType.PageSearcher,
    ReaderToolType.ArrowLeft,
    ReaderToolType.ArrowRight,
    ReaderToolType.FourZoomIn,
    ReaderToolType.IntervalZoomIn,
    ReaderToolType.AllScreen,
    ReaderToolType.FullScreen,
    ReaderToolType.Menu,
    ReaderToolType.StampCollection
  ],
  EditTools: [
    ReaderToolType.Painting,
    ReaderToolType.Highlighter,
    ReaderToolType.LaserPen,
    ReaderToolType.Shape,
    ReaderToolType.Line,
    ReaderToolType.DeleteAll,
    ReaderToolType.Delete,
    ReaderToolType.Text,
    ReaderToolType.Img,
    ReaderToolType.Mark,
    ReaderToolType.Link,
    ReaderToolType.Redo,
    ReaderToolType.Undo,
  ],
  TeachingTools: [
    ReaderToolType.Expression,
    ReaderToolType.NumberPicker,
    ReaderToolType.StickyNote,
    ReaderToolType.Whiteboard,
    ReaderToolType.Timer,
    ReaderToolType.Stopwatch,
    ReaderToolType.AnswerInteractiveTool,
    ReaderToolType.PaiNote,
  ],
  MathTools: [
    ReaderToolType.MathTools,
    ReaderToolType.MeasureTools,
  ],
  OfflineTools: [],
};

export const MeasureTools = [
  ReaderToolType.Protractor,
  ReaderToolType.ShortRuler,
  ReaderToolType.LongRuler,
  ReaderToolType.IsoscelesTriangle,
  ReaderToolType.RightTriangle,
];
